<template>
  <div class="bg-light-gray-300">
    <div class="grid-default container py-10 lg:py-20">
      <div class="col-span-12" :class="data.mediaPosition === 'Top' ? 'lg:order-1' : 'lg:order-2'">
        <div class="grid-default">
          <div class="overflow-hidden col-span-2 lg:col-span-4">
            <Image
              loading="lazy"
              class="h-full rounded-3xl object-cover"
              :src="
                data.mediaSmall.responsiveImage
                  ? data.mediaSmall.responsiveImage?.src
                  : data.mediaSmall.url
              "
              :srcset="
                data.mediaSmall.responsiveImage
                  ? data.mediaSmall.responsiveImage?.webpSrcSet
                  : undefined
              "
              :width="
                data.mediaSmall.responsiveImage
                  ? data.mediaSmall.responsiveImage?.width
                  : data.mediaSmall.width
              "
              :height="
                data.mediaSmall.responsiveImage
                  ? data.mediaSmall.responsiveImage?.height
                  : data.mediaSmall.height
              "
              :alt="data.mediaSmall.alt"
            />
          </div>
          <div class="overflow-hidden col-span-4 lg:col-span-8">
            <Image
              loading="lazy"
              class="aspect-3/2 rounded-3xl object-cover h-full"
              :src="data.media.responsiveImage ? data.media.responsiveImage?.src : data.media.url"
              :srcset="
                data.media.responsiveImage ? data.media.responsiveImage?.webpSrcSet : undefined
              "
              :width="data.media.width"
              :height="
                data.media.responsiveImage ? data.media.responsiveImage?.height : data.media.height
              "
              :alt="data.media.alt"
            />
          </div>
        </div>
      </div>
      <div
        class="col-span-12"
        :class="data.mediaPosition === 'Top' ? 'lg:order-2 lg:mt-28' : 'lg:order-1 lg:mb-28'"
      >
        <div class="grid-default">
          <div class="col-span-12 lg:col-span-5">
            <div class="mb-6 mt-6 text-3xl font-light lg:mb-14 lg:mt-0 lg:text-6xl">
              {{ data.title }}
            </div>
            <CallToAction
              v-if="Array.isArray(data.buttons) && data.buttons.length"
              class="mb-6 ml-0 lg:mb-0 lg:ml-0"
              :data="data.buttons"
            />
          </div>
          <div class="col-span-12 lg:col-span-7">
            <div
              v-if="data.html"
              ref="mediaText"
              class="prose text-base font-normal lg:text-4xl"
              v-html="data.html"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const mediaText = ref<HTMLElement | null>(null)

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)

  const tlMedia = gsap.timeline({
    scrollTrigger: {
      trigger: mediaText.value,
      start: 'top 90%',
      end: 'bottom 60%',
      scrub: true
    }
  })

  tlMedia.fromTo(
    mediaText.value,
    {
      opacity: 0
    },
    {
      opacity: 1
    }
  )
})

onUnmounted(() => {
  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>
